import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import ToDoList from './components/ToDoList';
import Login from './components/Login';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/todolist" element={<ToDoList />} />
      </Routes>
    </Router>

    // <div>
    // <ToDoList/>
    // </div>
  );
}

export default App;
