import React from 'react';
import '../components.css/ToDoList.scss';
import { useState, useEffect } from 'react';
import ProductLibrary from './productLibrary';
// const saveTasksToFile = require('../serwer/server');
import History from './History';
import TaskList from './TaskList';
import { jwtDecode as jwt_decode } from 'jwt-decode';


function ToDoList() {
	const [inputValue, setInputValue] = useState('');
	const [inputValue2, setInputValue2] = useState('');
	const [caloriesInputValue1, setCaloriesInputValue1] = useState('');
	const [caloriesInputValue2, setCaloriesInputValue2] = useState('');
	const [caloriesInputValue3, setCaloriesInputValue3] = useState('');
	const [totalAllCalories, setTotalAllCalories] = useState('0');
	const [totalAllProteins, setTotalAllProteins] = useState('0');
	const [state, setState] = useState('');
	const [tasks, setTasks] = useState([]);

	const [productLibrary, setProductLibrary] = useState([
		{ category: 'Nabiał', name: 'Mleko', calories: 42, proteins: 3, username: 'hej' },
		{ category: 'Nabiał', name: 'Mleko', calories: 42, proteins: 3, username: 'hej' },
		{ category: 'Nabiał', name: 'Mleko', calories: 42, proteins: 3, username: 'hej' },
		
		// Dodaj inne produkty wraz z ich kategoriami i liczbą kalorii
	]);

	//   useEffect(() => {
	//     fetch('/login', {
	//       method: 'POST',
	//       headers: {
	//         'Content-Type': 'application/json',
	//       },
	//       body: JSON.stringify({ credentials }), // Dane do logowania
	//     })
	//       .then((response) => response.json())
	//       .then((data) => {
	//         setUserId(data.userId);
	//       })
	//       .catch((error) => {
	//         console.error('Błąd podczas logowania:', error);
	//       });
	//   }, []);

	// Reszta komponentu ToDoList, gdzie możesz użyć userId

	function getUserNameFromToken(token) {
		try {
			const decodedToken = jwt_decode(token); // Dekodowanie tokenu JWT
			// Sprawdzenie czy token zawiera nazwę użytkownika
			if (decodedToken && decodedToken.username) {
				return decodedToken.username; // Zwracanie nazwy użytkownika z tokenu
			}
			return null; // Jeśli token nie zawiera nazwy użytkownika
		} catch (err) {
			console.error('Błąd dekodowania tokenu:', err);
			return null; // Jeśli wystąpił błąd podczas dekodowania tokenu
		}
	}
	useEffect(() => {
		const userToken = localStorage.getItem('userToken');
		const username = getUserNameFromToken(userToken); 
		fetch(`host491364.hostido.net.pl/getProducts/${username}`, {
		  headers: {
			Authorization: `Bearer ${userToken}`,
		  },
		})
		  .then((response) => {
			if (!response.ok) {
			  throw new Error('Network response was not ok');
			}
			return response.json();
		  })
		  .then((data) => {
			// Stwórz nową listę zawierającą unikalne produkty ze złączenia danych z fetch i produktów z productLibrary
			// console.log(data)
			const updatedProductLibrary = [
				...productLibrary,
				...data.filter((newProduct) => {
					// Sprawdź, czy dany produkt już istnieje w productLibrary
					return !productLibrary.some(
						(existingProduct) =>
							existingProduct.category === newProduct.category &&
							existingProduct.name === newProduct.name &&
							existingProduct.calories === newProduct.calories &&
							existingProduct.proteins === newProduct.proteins
					);
				}),
			];

			// Zaktualizuj stan productLibrary
			setProductLibrary(updatedProductLibrary);
		})
		.catch((error) => {
			console.error('Error fetching data:', error);
		});
}, [productLibrary]);
	
	// useEffect(() => {
	// 	fetch('http://localhost:5000/getProducts')
	// 		.then((response) => {
	// 			if (!response.ok) {
	// 				throw new Error('Network response was not ok');
	// 			}
	// 			return response.json();
	// 		})
	// 		.then((data) => {
	// 			// Stwórz nową listę zawierającą unikalne produkty ze złączenia danych z fetch i produktów z productLibrary
	// 			const updatedProductLibrary = [
	// 				...productLibrary,
	// 				...data.filter((newProduct) => {
	// 					// Sprawdź, czy dany produkt już istnieje w productLibrary
	// 					return !productLibrary.some(
	// 						(existingProduct) =>
	// 							existingProduct.category === newProduct.category &&
	// 							existingProduct.name === newProduct.name &&
	// 							existingProduct.calories === newProduct.calories &&
	// 							existingProduct.proteins === newProduct.proteins
	// 					);
	// 				}),
	// 			];

	// 			// Zaktualizuj stan productLibrary
	// 			setProductLibrary(updatedProductLibrary);
	// 		})
	// 		.catch((error) => {
	// 			console.error('Error fetching data:', error);
	// 		});
	// }, [productLibrary]); // Dodaj productLibrary jako zależność, aby wywołać useEffect przy zmianie productLibrary
	useEffect(() => {
		// Tutaj wykonaj zapytanie do serwera, aby pobrać dane z endpointa
		fetch('http://localhost:5000/getTasks')
			.then((response) => response.json())
			.then((data) => {
				setTasks(data); // Ustaw dane w stanie
			})
			.catch((error) => {
				console.error('There was a problem fetching tasks:', error);
			});
	}, []);
	useEffect(() => {
		let totalCalories = 0;
		let totalProteins = 0;

		tasks.forEach((task) => {
			totalCalories += parseFloat(task.productWholeCalories);
			totalProteins += parseFloat(task.proteins);
		});

		setTotalAllCalories(totalCalories);
		setTotalAllProteins(totalProteins);
	}, [tasks]);

	const handleChange = (e) => {
		setInputValue(e.target.value);
	};
	const handleChange2 = (e) => {
		setInputValue2(e.target.value);
	};
	const handleSelectProduct = (selectedProduct) => {
		console.log(selectedProduct); // Sprawdzenie zawartości selectedProduct
		const gramsValue = selectedProduct.grams || 100;
		const calories = (selectedProduct.calories * gramsValue) / 100;
		const proteins = (selectedProduct.proteins * gramsValue) / 100;

		const newTask = {
			id: tasks.length + 1,
			name: selectedProduct.name,
			category: '',
			date: new Date().toLocaleDateString(),
			grams: gramsValue,
			calories: selectedProduct.calories,
			proteins: proteins,
			productWholeCalories: calories,
		};

		const updatedTasks = [...tasks, newTask];
		setTasks(updatedTasks);
	};

	// useEffect(() => {
	//   saveTasksToFile(tasks);
	// }, [tasks]);

	// const addProductToDB = (name, category, calories, proteins) => {
	// 	fetch('http://localhost:5000/addProduct', {
	// 		method: 'POST',
	// 		headers: {
	// 			'Content-Type': 'application/json',
	// 		},
	// 		body: JSON.stringify({ name, category, calories, proteins }),
	// 		mode: 'cors', // Ustawienie trybu na 'cors'
	// 	})
	// 		.then((response) => {
	// 			if (!response.ok) {
	// 				throw new Error('Network response was not ok');
	// 			}
	// 			return response.json();
	// 		})
	// 		.then((data) => {
	// 			console.log('Product added:', data);
	// 			// Tutaj możesz dodać logikę obsługi sukcesu dodawania produktu
	// 		})
	// 		.catch((error) => {
	// 			console.error('There was a problem with the fetch operation:', error);
	// 			// Tutaj możesz dodać logikę obsługi błędu zapisu do bazy danych
	// 		});
	// };

	const addProductToDB = (name, category, calories, proteins) => {
		const username = getUserNameFromToken(userToken)
		fetch('http://localhost:5000/addProduct', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `bearer ${userToken}`
			},
			body: JSON.stringify({ name, category, calories, proteins, username }),
			mode: 'cors', // Ustawienie trybu na 'cors'
		})
			.then((response) => {
				if (!response.ok) {
					throw new Error('Network response was not ok');
				}
				return response.json();
			})
			.then((data) => {
				console.log('Product added:', data);
				// Tutaj możesz dodać logikę obsługi sukcesu dodawania produktu
			})
			.catch((error) => {
				console.error('There was a problem with the fetch operation:', error);
				// Tutaj możesz dodać logikę obsługi błędu zapisu do bazy danych
			});
	};

	const addNewProduct = () => {
		const newProduct = {
			name: inputValue,
			category: inputValue2,
			calories: parseInt(caloriesInputValue2), // Parsowanie do liczby całkowitej
			proteins: parseInt(caloriesInputValue3), // Parsowanie do liczby całkowitej
		};

		// Dodanie produktu do bazy danych
		addProductToDB(
			newProduct.name,
			newProduct.category,
			newProduct.calories,
			newProduct.proteins
		);

		setProductLibrary([...productLibrary, newProduct]);

		// Wyczyszczenie stanów
		setInputValue('');
		setInputValue2('');
		setCaloriesInputValue1('');
		setCaloriesInputValue2('');
		setCaloriesInputValue3('');
	};

	// Funkcja sprawdzająca poprawność tokenu JWT i pobierająca nazwę użytkownika
	

	const userToken = localStorage.getItem('userToken'); // Pobieranie tokenu z local storage
	// console.log(userToken);

	 // Odczytanie nazwy użytkownika z tokenu

	// if (username) {
	// 	console.log('Nazwa użytkownika:', username); // Wyświetlenie nazwy użytkownika
	// } else {
	// 	console.log('Nieprawidłowy token lub brak nazwy użytkownika.');
	// }
	const addTaskToDB = (tasks, userToken) => {
		const username = getUserNameFromToken(userToken); // Odczytanie nazwy użytkownika z tokenu
	
		fetch('http://localhost:5000/addTask', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${userToken}`,
			},
			body: JSON.stringify({ tasks: tasks.map(task => ({  // Mapowanie każdego zadania z dodaniem nazwy użytkownika
				username,
				name: task.name,
				category: task.category,
				date: task.date,
				grams: task.grams,
				calories: task.calories,
				proteins: task.proteins,
				productWholeCalories: task.productWholeCalories,
			})) }),
			mode: 'cors',
		})
		.then((response) => {
			if (!response.ok) {
				throw new Error('Network response was not ok');
			}
			return response.json();
		})
		.then((data) => {
			console.log('Tasks added:', data);
			// Dodaj logikę obsługi sukcesu dodawania zadań do bazy danych
		})
		.catch((error) => {
			console.error('There was a problem with the fetch operation:', error);
			// Dodaj logikę obsługi błędu zapisu zadań do bazy danych
		});
	};
	// const addTaskToDB = (tasks, userToken, username) => {
	// 	console.log('User token sent to server:', userToken); 
	// 	console.log(userToken); // Przed wywołaniem fetch
	// 	const tasksToSend = tasks.map((task) => ({
	// 		username: username,
	// 		name: task.name,
	// 		category: task.category,
	// 		date: task.date,
	// 		grams: task.grams,
	// 		calories: task.calories,
	// 		proteins: task.proteins,
	// 		productWholeCalories: task.productWholeCalories,
	// 	}));
	
	// 	fetch('http://localhost:5000/addTask', {
	// 		method: 'POST',
	// 		headers: {
	// 			'Content-Type': 'application/json',
	// 			Authorization: `Bearer ${userToken}`,
	// 		},
	// 		body: JSON.stringify({ tasks: tasksToSend }), // Wysyłanie obiektu z tablicą zadań
	// 		mode: 'cors',
	// 	})
	// 	.then((response) => {
	// 		if (!response.ok) {
	// 			throw new Error('Network response was not ok');
	// 		}
	// 		return response.json();
	// 	})
	// 	.then((data) => {
	// 		console.log('Tasks added:', data);
	// 		// Dodaj logikę obsługi sukcesu dodawania zadań do bazy danych
	// 	})
	// 	.catch((error) => {
	// 		console.error('There was a problem with the fetch operation:', error);
	// 		// Dodaj logikę obsługi błędu zapisu zadań do bazy danych
	// 	});
	// };

	// const addTaskToDB = (tasks, totalProteins, totalCalories) => {
	// 	const tasksArray = Array.isArray(tasks) ? tasks : [];
	// 	console.log(tasks);
	// 	const tasksToSend = tasksArray.map((task) => {
	// 		return {
	// 			id: task.id,
	// 			name: task.name,
	// 			category: task.category,
	// 			date: task.date,
	// 			grams: task.grams,
	// 			calories: task.calories,
	// 			proteins: task.proteins,
	// 			productWholeCalories: task.productWholeCalories,
	// 		};
	// 	});

	// 	fetch('http://localhost:5000/addTask', {
	// 		method: 'POST',
	// 		headers: {
	// 			'Content-Type': 'application/json',
	// 		},
	// 		body: JSON.stringify(tasksToSend),
	// 		mode: 'cors',
	// 	})
	// 		.then((response) => {
	// 			if (!response.ok) {
	// 				throw new Error('Network response was not ok');
	// 			}
	// 			return response.json();
	// 		})
	// 		.then((data) => {
	// 			console.log('Tasks added:', data);
	// 			// Tutaj dodaj logikę obsługi sukcesu dodawania zadań do bazy danych
	// 		})
	// 		.catch((error) => {
	// 			console.error('There was a problem with the fetch operation:', error);
	// 			// Tutaj dodaj logikę obsługi błędu zapisu zadań do bazy danych
	// 		});
	// };
	// const addTaskToDB = (tasks, totalProteins, totalCalories, userToken, userId) => {
	// 	const tasksArray = Array.isArray(tasks) ? tasks : [];

	// 	console.log('Wartość tasks:', tasks); // Sprawdź wartość zmiennej tasks
	// 	console.log('Czy tasksArray jest tablicą:', Array.isArray(tasksArray)); // Sprawdź, czy tasksArray jest tablicą
	// 	// ... reszta kodu ...
	// 	const tasksToSend = tasksArray.map((task) => {
	// 	  return {
	// 		userId: userId, // Dodanie userId do obiektu zadań
	// 		id: task.id,
	// 		name: task.name,
	// 		category: task.category,
	// 		date: task.date,
	// 		grams: task.grams,
	// 		calories: task.calories,
	// 		proteins: task.proteins,
	// 		productWholeCalories: task.productWholeCalories,
	// 	  };
	// 	});

	// 	fetch('http://localhost:5000/addTask', {
	// 	  method: 'POST',
	// 	  headers: {
	// 		'Content-Type': 'application/json',
	// 		'Authorization': `Bearer ${userToken}` // Dodanie tokenu JWT do nagłówka
	// 	  },
	// 	  body: JSON.stringify(tasksToSend),
	// 	  mode: 'cors',

	// 	})
	// 	  .then((response) => {
	// 		if (!response.ok) {
	// 		  throw new Error('Network response was not ok');
	// 		}
	// 		return response.json();
	// 	  })
	// 	  .then((data) => {
	// 		console.log('Tasks added:', data);
	// 		// Tutaj dodaj logikę obsługi sukcesu dodawania zadań do bazy danych
	// 	  })
	// 	  .catch((error) => {
	// 		console.error('There was a problem with the fetch operation:', error);
	// 		// Tutaj dodaj logikę obsługi błędu zapisu zadań do bazy danych
	// 	  });
	//   };

	const handleCaloriesChange1 = (e) => {
		const inputValue = e.target.value;
		// Sprawdzenie czy wartość jest numeryczna
		const isNumber = /^\d+$/.test(inputValue);

		if (isNumber || inputValue === '') {
			setCaloriesInputValue1(inputValue);
		}
	};
	const handleCaloriesChange3 = (e) => {
		const inputValue = e.target.value;
		// Sprawdzenie czy wartość jest numeryczna
		const isNumber = /^\d+$/.test(inputValue);

		if (isNumber || inputValue === '') {
			setCaloriesInputValue3(inputValue);
		}
	};

	// Analogicznie dla drugiego inputa z kaloriami
	const handleCaloriesChange2 = (e) => {
		const inputValue = e.target.value;
		const isNumber = /^\d+$/.test(inputValue);

		if (isNumber || inputValue === '') {
			setCaloriesInputValue2(inputValue);
		}
	};
	// useEffect(() => {
	//   allCalories();
	// }, [tasks]);

	const handleClick = (index) => {
		const currentDate = new Date().toLocaleDateString();

		const newTask = {
			id: tasks.length + 1,
			name: inputValue,
			category: inputValue2,
			date: currentDate,
			grams: caloriesInputValue1,
			calories: caloriesInputValue1,
			proteins: caloriesInputValue1 * caloriesInputValue3 * 0.01,
			productWholeCalories: caloriesInputValue1 * caloriesInputValue2 * 0.01,
		};

		// Dodaj logi, aby sprawdzić wartości zmiennych
		console.log('New Task:', newTask);

		if (
			inputValue !== '' &&
			caloriesInputValue1 !== '' &&
			caloriesInputValue2 !== ''
		) {
			// Zapisz nowe zadanie do bazy danych
			// addTaskToDB([...tasks, newTask]);
			setTasks([...tasks, newTask]);
			setInputValue('');
			setInputValue2('');
			setCaloriesInputValue1('');
			setCaloriesInputValue2('');
			setState('');
		} else {
			setState('wpisz coś');
		}
	};
	const deleteTaskAndTitle = (index) => {
		const updatedTasks = tasks.filter((_, taskIndex) => taskIndex !== index);
		setTasks(updatedTasks);
	};
	const deleteAll = () => {
		setTasks([]);
	};
	const countCalories = () => {
		let productCalories = 0;
		tasks.forEach((task) => {
			productCalories = (task.grams * task.calories) / 100;
		});
		return productCalories;
	};

	// const totalwholeDayCalories = () => {
	//   const allCaloriesValue = allCalories();
	//   const totalAll = allCaloriesValue + 5;
	//   return totalAll;
	// }{totalwholeDayCalories()}
	return (
		<div className='ToDoList'>
			<h1 className='h1Main'>Zjedzone posiłki</h1>
			<label>
				Wpisz posiłek
				<br />
				<input type='text' value={inputValue} onChange={handleChange} />
			</label>
			<label>
				Wpisz kategorie
				<br />
				<textarea
					type='text'
					value={inputValue2}
					onChange={handleChange2}></textarea>
			</label>
			<label>
				<input
					placeholder='gramy'
					type='tel'
					value={caloriesInputValue1}
					onChange={handleCaloriesChange1}
				/>{' '}
				<br />
				Wpisz ilość g
			</label>
			<label>
				<input
					placeholder='białko'
					type='tel'
					value={caloriesInputValue3}
					onChange={handleCaloriesChange3}
				/>{' '}
				<br />
				Wpisz ilość g białka
			</label>
			<label>
				<input
					placeholder='kalorie'
					type='tel'
					value={caloriesInputValue2}
					onChange={handleCaloriesChange2}
				/>{' '}
				<br />
				Wpisz ilość kalorii na 100g
			</label>
			<button onClick={handleClick}>Dodaj składnik</button>
			<button onClick={addNewProduct}>Dodaj nowy product</button>
			<button onClick={() => addTaskToDB(tasks, userToken)}>
				Zapisz historię
			</button>
			<h3 className='h3'>
				Suma kalorii w potrawie {totalAllCalories > 0 ? totalAllCalories : null}
			</h3>
			<h3 className='h3'>
				Suma białka w potrawie {totalAllProteins > 0 ? totalAllProteins : null}
			</h3>
			<p className='allCaloriesP'> kalorii</p>
			<button onClick={deleteAll}>Usuń wszystkie produkty</button>
			<ProductLibrary
				products={productLibrary}
				onSelectProduct={handleSelectProduct}
			/>
			<div className='productContainer'>
				{tasks.map((task, index) => (
					<div className='product' key={index}>
						{/* <p>{task.id}</p> */}
						<h1>{task.name}</h1>
						<p>{task.category}</p>
						<p>{task.date}</p>
						<p>waga {task.grams} g</p>
						<p>białko {task.proteins} g</p>
						{/* <p>{task.calories} kalorii w 100g</p> */}
						<p>
							{' '}
							Wszystie kalorie ze składnika: {task.productWholeCalories} kalorii
						</p>
						<button onClick={() => deleteTaskAndTitle(index)}>
							Usuń składnik
						</button>
					</div>
				))}
			</div>
			<p className='errorP'>{state}</p>
			<TaskList />
			{/* <History /> */}
		</div>
	);
}

export default ToDoList;
