import React, { useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { jwtDecode as jwt_decode } from 'jwt-decode';

const TaskList = () => {
	const [tasks, setTasks] = useState([]);
	const [selectedDate, setSelectedDate] = useState(null);
	const [selectedTasksForDate, setSelectedTasksForDate] = useState([]);

	const sumCaloriesForDay = () => {
		let totalCalories = 0;

		selectedTasksForDate.forEach((task) => {
			totalCalories += parseFloat(task.productWholeCalories);
		});

		return totalCalories;
	};

	const sumProteinsForDay = () => {
		let totalProteins = 0;

		selectedTasksForDate.forEach((task) => {
			totalProteins += parseFloat(task.proteins);
		});

		return totalProteins;
	};

	// const handleDateChange = async (date) => {
	//   setSelectedDate(date);
	//   const userToken = localStorage.getItem('userToken')
	//   try {
	//     // Pobierz token z kontekstu aplikacji lub innego źródła

	//     const response = await fetch('http://localhost:5000/getAllTasks', {
	//       headers: {
	//         Authorization: `Bearer ${userToken}` // Przesłanie tokenu w nagłówku żądania
	//       }
	//     });

	//     if (!response.ok) {
	//       throw new Error('Network response was not ok');
	//     }

	//     const data = await response.json();

	//     // Filtruj zadania, które mają odpowiadającą datę
	//     const tasksForSelectedDate = data.filter(
	//       (task) => task.date === date.toLocaleDateString()
	//     );
	//     setSelectedTasksForDate(tasksForSelectedDate);
	//   } catch (error) {
	//     console.error('Błąd podczas pobierania danych:', error);
	//   }
	// };

	// const handleDateChange = async (date) => {
	// 	setSelectedDate(date);
	// 	const userToken = localStorage.getItem('userToken');

	//   console.log(userToken)
	// 	try {
	// 		const response = await fetch('http://localhost:5000/allTasks', {
	// 			headers: {
	// 				Authorization: `Bearer ${userToken}`, // Przesłanie tokenu w nagłówku żądania
	// 			},
	// 		});

	// 		if (!response.ok) {
	// 			throw new Error('Network response was not ok');
	// 		}

	// 		const data = await response.json();

	// 		// Filtruj zadania, które mają odpowiadającą datę
	// 		const tasksForSelectedDate = data.filter(
	// 			(task) => task.date === date.toLocaleDateString()
	// 		);
	// 		setSelectedTasksForDate(tasksForSelectedDate);
	// 	} catch (error) {
	// 		console.error('Błąd podczas pobierania danych:', error);
	// 	}
	// };

	const getUsernameFromToken = (token) => {
		try {
			const decodedToken = jwt_decode(token);
			return decodedToken.username;
		} catch (error) {
			console.error('Błąd podczas dekodowania tokenu:', error);
			return null;
		}
	};

	const handleDateChange = async (date) => {
		setSelectedDate(date);

		try {
			const userToken = localStorage.getItem('userToken');
			const username = getUsernameFromToken(userToken); // Funkcja do pobrania nazwy użytkownika z tokenu

			console.log(username);
			const response = await fetch(
				`http://localhost:5000/allTasks/${username}`,
				{
					headers: {
						Authorization: `Bearer ${userToken}`, // Przesłanie tokenu w nagłówku żądania
					},
				}
			);

			if (!response.ok) {
				throw new Error('Network response was not ok');
			}

			const data = await response.json();

			// Filtruj zadania, które mają odpowiadającą datę
			const tasksForSelectedDate = data.filter(
				(task) => task.date === date.toLocaleDateString()
			);
			setSelectedTasksForDate(tasksForSelectedDate);
		} catch (error) {
			console.error('Błąd podczas pobierania danych:', error);
		}
	};

	return (
		<div>
			<h1>Task List</h1>
			<div>
				<DatePicker selected={selectedDate} onChange={handleDateChange} />
			</div>
			<div>
				{selectedTasksForDate.length > 0 ? (
					<div>
						<h2>Zadania dla wybranej daty:</h2>
						{selectedTasksForDate.map((task, index) => (
							<div key={index}>
								<p>Name: {task.name}</p>
								<p>Category: {task.category}</p>
								<p>Date: {task.date}</p>
								<p>Proteins: {task.proteins}</p>
								<p>Kalorie: {task.calories}</p>
								<p>Wszytskie kalorie: {task.productWholeCalories}</p>
							</div>
						))}
					</div>
				) : (
					<p>Brak zadań dla wybranej daty</p>
				)}
			</div>
			<h3>Suma kalorii dla wybranego dnia: {sumCaloriesForDay()}</h3>
			<h3>Suma wartości protein dla wybranego dnia: {sumProteinsForDay()}</h3>
		</div>
	);
};

export default TaskList;
