import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import CheckStatus from './CheckStatus';
import Word from './Words';
const Login = () => {
	const [isRegistering, setIsRegistering] = useState(false);
	const [username, setUsername] = useState('');
	const [password, setPassword] = useState('');
	const [confirmPassword, setConfirmPassword] = useState('');
	const [email, setEmail] = useState(''); 

	const navigate = useNavigate();	// const handleLogin = async () => {
	//     if (!isRegistering && username !== '' && password !== '') {
	//       try {
	//         const response = await fetch('http://localhost:5000/login', {
	//           method: 'POST',
	//           headers: {
	//             'Content-Type': 'application/json',
	//           },
	//           body: JSON.stringify({ username, password }),
	//         });

	//         const data = await response.json();
	//         if (response.status === 200) {
	//           console.log('Zalogowano użytkownika:', data);
	//           navigate('/todolist');
	//           // Tutaj możesz obsłużyć zalogowanego użytkownika, np. przekierować na inną stronę
	//         } else {
	//           console.error('Błąd logowania:', data.message);
	//           // Tutaj możesz obsłużyć błędne logowanie, np. wyświetlić komunikat dla użytkownika
	//         }
	//       } catch (error) {
	//         console.error('Błąd logowania:', error);
	//       }
	//     } else if (
	//       isRegistering &&
	//       username !== '' &&
	//       password !== '' &&
	//       confirmPassword !== '' &&
	//       email !== ''
	//     ) {
	//       if (password === confirmPassword) {
	//         try {
	//           const response = await fetch('http://localhost:5000/register', {
	//             method: 'POST',
	//             headers: {
	//               'Content-Type': 'application/json',
	//             },
	//             body: JSON.stringify({ username, password, email }),
	//           });

	//           const data = await response.json();
	//           console.log('Odpowiedź po rejestracji:', data);
	//         } catch (error) {
	//           console.error('Błąd rejestracji:', error);
	//         }
	//       } else {
	//         alert('Hasła nie pasują do siebie.');
	//       }
	//     } else {
	//       alert('Wszystkie pola są wymagane.');
	//     }
	//   };

	const handleLogin = async () => {
		try {
			let endpoint = '';
			let bodyData = {};

			if (!isRegistering && username !== '' && password !== '') {
				endpoint = 'https://intake-app-server-production.up.railway.app/login';
				bodyData = { username, password };
			} else if (
				isRegistering &&
				username !== '' &&
				password !== '' &&
				confirmPassword !== '' &&
				email !== ''
			) {
				if (password === confirmPassword) {
					endpoint = 'https://intake-app-server-production.up.railway.app/register';
					bodyData = { username, password, email }; 
				} else {
					alert('Hasła nie pasują do siebie.');
					return;
				}
			} else {
				alert('Wszystkie pola są wymagane.');
				return;
			}

			const response = await fetch(endpoint, {
				method: 'POST',
				
				headers: {
					'Content-Type': 'application/json',
					credentials: 'include',
				},
				mode: 'cors',
				body: JSON.stringify(bodyData),
			});

			console.log('Status odpowiedzi:', response.status);
			const data = await response.json();
			if (response.status === 200) {
				console.log('Odpowiedź serwera:', data);

				if (data.token) {
					localStorage.setItem('userToken', data.token); // Zapisz token w localStorage
					navigate('/todolist');
					// Tutaj możesz obsłużyć zalogowanego użytkownika, np. przekierować na inną stronę
				}
			} else {
				console.error('Błąd logowania:', data.message || 'Błąd logowania');
				// Tutaj możesz obsłużyć błędne logowanie lub rejestrację
			}
		} catch (error) {
			console.error('Błąd logowania:', error);
		}
	};

	return (
		<div>
			<h2>{isRegistering ? 'Rejestracja' : 'Logowanie'}</h2>
			<div>
				<label>
					Nazwa użytkownika:
					<input
						type='text'
						value={username}
						onChange={(e) => setUsername(e.target.value)}
					/>
				</label>
			</div>
			<div>
				<label>
					Hasło:
					<input
						type='password'
						value={password}
						onChange={(e) => setPassword(e.target.value)}
					/>
				</label>
			</div>
			{isRegistering && (
				<div>
					<label>
						Potwierdź hasło:
						<input
							type='password'
							value={confirmPassword}
							onChange={(e) => setConfirmPassword(e.target.value)}
						/>
					</label>
				</div>
			)}
			{isRegistering && (
				<div>
					<label>
						Email:
						<input
							type='email'
							value={email}
							onChange={(e) => setEmail(e.target.value)}
						/>
					</label>

					<CheckStatus />

					{/* <Word /> */}
				</div>
			)}
			<button onClick={handleLogin}>
				{isRegistering ? 'Zarejestruj się' : 'Zaloguj'}
			</button>
			<p>
				{isRegistering ? 'Masz już konto?' : 'Nie masz konta?'}
				<span
					style={{ color: 'blue', cursor: 'pointer' }}
					onClick={() => setIsRegistering(!isRegistering)}>
					{isRegistering ? ' Zaloguj się' : ' Zarejestruj się'}
				</span>
			</p>
		</div>
	);
};

export default Login;
