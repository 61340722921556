import React, { useEffect } from 'react';

function FetchData() {

	const sendDataToServer = async () => {
		try {
		  const dataToSend = ['example', 'data', 'to', 'send']; // Twoje dane do wysłania
		  const response = await fetch('http://localhost:5000', {
			method: 'POST',
			headers: {
			  'Content-Type': 'application/json',
			  
			},
			body: JSON.stringify({ data: dataToSend }),
		  });

		  if (response.ok) {
			const receivedData = await response.json();
			console.log('Odebrane dane:', receivedData);
			// Obsłuż otrzymane dane z serwera
		  } else {
			console.error('Błąd podczas wysyłania danych');
		  }
		} catch (error) {
		  console.error('Błąd podczas połączenia:', error);
		}
	  };

	// const testUrl = `${process.env.REACT_APP_BACKEND_URL}/test`;
	const testUrl = `http://localhost:5000/test`; // Zmodyfikowany URL

	useEffect(() => {
		fetch(testUrl, {
			method: 'GET',

			headers: {
				'Content-Type': 'application/json',
				
				// Dodaj inne nagłówki, jeśli są potrzebne
			},
			// credentials: 'include', // Ustawienie credentails na 'include', jeśli potrzebne są ciasteczka
		})
			.then((response) => {
				console.log('Status odpowiedzi testUrl:', response.status);
				return response.text();
			})
			.then((data) => {
				console.log('Odpowiedź z serwera testUrl:', data);
			})
			.catch((error) => {
				console.error('Błąd podczas połączenia z testUrl:', error);
			});
	}, []);

	return (
		<div>
			<p>Sprawdź konsolę, aby zobaczyć odpowiedzi z serwera.</p>
			<button onClick={sendDataToServer}>ww</button>
		</div>
	);
}

export default FetchData;
// import React, { useState, useReducer, useEffect, useRef } from 'react';

// const initialState = {
// 	url: 'https://intake-app-server-production.up.railway.app/test',
// 	sending: false,
// 	response: null,
// };

// const reducer = (state, action) => {
// 	switch (action.type) {
// 		case 'setUrl':
// 			return {
// 				...state,
// 				url: action.url,
// 			};
// 		case 'startSending':
// 			return {
// 				...state,
// 				sending: true,
// 			};
// 		case 'stopSending':
// 			return {
// 				...state,
// 				sending: false,
// 			};
// 		case 'setResponse':
// 			return {
// 				...state,
// 				response: action.response,
// 			};
// 		default:
// 			return state;
// 	}
// };

// function FetchData() {
// 	const [state, dispatch] = useReducer(reducer, initialState);
// 	const [testUrlRef, setTestUrlRef] = useRef(
// 		'https://intake-app-server-production.up.railway.app/test'
// 	);

// 	const sendDataToServer = async () => {
// 		try {
// 			dispatch({ type: 'startSending' });

// 			const response = await fetch(testUrlRef.current, {
// 				method: 'POST',
// 				headers: {
// 					'Content-Type': 'application/json',
// 					'Access-Control-Allow-Origin': '*',
// 				},
// 				body: JSON.stringify({
// 					data: state.data,
// 				}),
// 			});

// 			dispatch({ type: 'stopSending' });

// 			if (response.ok) {
// 				const receivedData = await response.json();
// 				dispatch({ type: 'setResponse', response: receivedData });
// 			} else {
// 				console.error('Błąd podczas wysyłania danych');
// 			}
// 		} catch (error) {
// 			console.error('Błąd podczas połączenia:', error);
// 		}
// 	};

// 	useEffect(() => {
// 		dispatch({ type: 'setUrl', url: testUrlRef.current });
// 	}, [testUrlRef]);

// 	return (
// 		<div>
// 			<p>Sprawdź konsolę, aby zobaczyć odpowiedzi z serwera.</p>
// 			<input
// 				type='text'
// 				placeholder='Wprowadź dane do wysłania'
// 				onChange={(event) =>
// 					dispatch({ type: 'addData', data: event.target.value })
// 				}
// 			/>
// 			<button
// 				onClick={() => {
// 					dispatch({ type: 'sendData' });
// 				}}>
// 				Wyślij dane
// 			</button>
// 			{state.sending && <p>Trwa wysyłanie danych...</p>}
// 			{state.response && <pre>{JSON.stringify(state.response, null, 2)}</pre>}
// 		</div>
// 	);
// }

// export default FetchData;

