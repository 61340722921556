import React, { useState, useEffect } from 'react';

function ProductLibrary({ products, onSelectProduct }) {
	const [selectedCategory, setSelectedCategory] = useState('');
	const [selectedProductIndex, setSelectedProductIndex] = useState('');
	const [grams, setGrams] = useState('');
	const [totalCalories, setTotalCalories] = useState(0);
	const [newProductsInArray, setNewProductsInArray] = useState([]);

	useEffect(() => {
		if (selectedCategory !== '') {
			const newProductsArray = products.filter(
				(product) => product.category === selectedCategory
			);
			setNewProductsInArray(newProductsArray);
		} else {
			setNewProductsInArray([]);
		}
		// setSelectedProductIndex('')
	}, [selectedCategory, products]);

	const handleCategoryChange = (e) => {
		setSelectedCategory(e.target.value);
	};
	const handleGramsChange = (e) => {
		setGrams(e.target.value);
	};
	const handleProductChange = (e) => {
		setSelectedProductIndex(e.target.value);
	};
	const handleAddProduct = () => {
		const selectedProduct = newProductsInArray[selectedProductIndex];
		const gramsValue = grams || 100 
		const calories =  selectedProduct.calories
		const proteins =  selectedProduct.proteins
		const newProduct = {
			...selectedProduct,
      id: selectedProduct.id,
      name: selectedProduct.name,
      category: selectedProduct.category,
			grams: gramsValue,
      date: selectedProduct.date,
			productWholeCalories: calories,
			proteins: proteins,



		};

		onSelectProduct(newProduct);
	};
	return (
		<div className='productLibrary'>
			<h2>Biblioteka produktów</h2>
			<div>
			{/* <select value={selectedCategory} onChange={handleCategoryChange}>
        <option value=''>Wybierz kategorię</option>
        {[...new Set(products.map((product) => product.category))].map((category, index) => (
          <option key={index} value={category}>
            {category}
          </option>
        ))}
      </select> */}
<select value={selectedCategory} onChange={handleCategoryChange}>
					<option value=''>Wybierz kategorię</option>
					{Array.from(new Set(products.map((product) => product.category))).map(
						(category, index) => (
							<option key={index} value={category}>
								{category}
							</option>
						)
					)}
				</select>
				
				{selectedCategory && (
					<select value={selectedProductIndex} onChange={handleProductChange}>
						<option value=''>Wybierz produkt</option>
						{newProductsInArray.map((product, index) => (
							<option key={index} value={index}>
								{product.name} - {product.calories} kalorii na 100g{' '}
								{product.proteins} g białka na 100g 
							</option>
						))}
					</select>
				)}
				<input
					type='number'
					value={grams}
					onChange={handleGramsChange}
					placeholder='Wpisz ilość gram'
				/>

				<button onClick={handleAddProduct}>dodaj</button>
			</div>
		</div>
	);
}

export default ProductLibrary;
